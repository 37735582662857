import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62f9453e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "w-100" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "form-text text-muted"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MandatoryFieldFlag = _resolveComponent("MandatoryFieldFlag")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_VueDatepicker = _resolveComponent("VueDatepicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
            _createTextVNode(_toDisplayString(_ctx.label), 1),
            (_ctx.required)
              ? (_openBlock(), _createBlock(_component_MandatoryFieldFlag, { key: 0 }))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_VueDatepicker, _mergeProps({
        modelValue: _ctx.value,
        "onUpdate:modelValue": _ctx.onChange,
        cancelText: _ctx.cancelText,
        selectText: _ctx.selectText,
        format: _ctx.format,
        inputClassName: "form-control",
        noButtonLabel: _ctx.noButtonLabel,
        weekNumName: _ctx.weekNumName,
        locale: _ctx.locale,
        ariaLabels: _ctx.ariaLabels,
        "enable-time-picker": _ctx.enableTimePicker,
        "time-picker": _ctx.timePicker,
        "hide-input-icon": _ctx.hideInputIcon,
        "disabled-dates": _ctx.disabledDates,
        "disabled-week-days": _ctx.disabledWeekDays
      }, _ctx.$props, {
        onInvalid: _cache[0] || (_cache[0] = ($event: any) => (_ctx.validityMessage ? _ctx.setValidityMessage(_ctx.validityMessage) : null)),
        class: "datepicker"
      }), {
        "input-icon": _withCtx(() => [
          _createVNode(_component_font_awesome_icon, {
            icon: ['fas', 'calendar'],
            class: "datepicker-icon"
          })
        ]),
        _: 1
      }, 16, ["modelValue", "onUpdate:modelValue", "cancelText", "selectText", "format", "noButtonLabel", "weekNumName", "locale", "ariaLabels", "enable-time-picker", "time-picker", "hide-input-icon", "disabled-dates", "disabled-week-days"])
    ]),
    (_ctx.helpText)
      ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.helpText), 1))
      : _createCommentVNode("", true)
  ]))
}