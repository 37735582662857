
import { Options, Vue } from "vue-class-component";
import VueDatepicker from "@vuepic/vue-datepicker";
import MandatoryFieldFlag from "@/components/Form/MandatoryFieldFlag.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import { PropType } from "vue";

@Options({
  components: {
    VueDatepicker,
    MandatoryFieldFlag,
  },
  props: {
    /**
     * The current value
     */
    value: {
      type: [Date] as PropType<Date>,
      required: false,
    },
    /**
     * The label for the input
     */
    label: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * The help text for the input
     */
    helpText: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * If the input is required
     */
    required: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /**
     * Custom validity message
     */
    validityMessage: {
      type: String as PropType<string>,
      required: false,
    },
    /**
     * Makes DatePicker field clearable or not
     */
    clearable: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /**
     * Enables or disables the time picker
     */
    enableTimePicker: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    /**
     * Changes datepicker mode to select only time
     */
    timePicker: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /**
     * Hides the calendar icon in the input field
     */
    hideInputIcon: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    /**
     * Formats the value of the date(s) in the input field
     */
    format: {
      type: String as PropType<string>,
      required: false,
      default: "yyyy-MM-dd",
    },
    /**
     * Disables a selection of specific dates
     */
    disabledDates: {
      type: [Array, Function] as PropType<
        Date[] | string[] | ((date: Date) => boolean)
      >,
      required: false,
    },
    /**
     * Disables specific days of the week
     * 0-6, 0 is Sunday, 6 is Saturday
     */
    disabledWeekDays: {
      type: [Array] as PropType<string[] | number[]>,
      required: false,
    },
  },
  emits: ["update:value"],
  computed: {
    cancelText(): string {
      return this.$t("shared.cancel");
    },
    selectText(): string {
      return this.$t("shared.choose");
    },
    noButtonLabel(): string {
      return this.$t("shared.form.date-picker.now");
    },
    weekNumName(): string {
      return this.$t("shared.form.date-picker.week");
    },
    locale(): string {
      return this.$root.$i18n.locale === "sv" ? "sv-SE" : "en-US";
    },
    ariaLabels() {
      return {
        incrementvalue: (type: "hours" | "minutes" | "seconds") =>
          this.$t(`shared.form.date-picker.increment-${type}`),
        decrementValue: (type: "hours" | "minutes" | "seconds") =>
          this.$t(`shared.form.date-picker.decrement-${type}`),
        nextMonth: this.$t("shared.form.date-picker.next-month"),
        prevMonth: this.$t("shared.form.date-picker.prev-month"),
      };
    },
  },
  methods: {
    /**
     * Sets a custom validity message
     *
     * @param {string} message The message
     */
    setValidityMessage(message: string): void {
      this.$refs.input.setCustomValidity(message);
    },
    /**
     * Runs when value of picker changes
     *
     * @param {Date} date The selected date
     */
    onChange(date: Date) {
      this.$emit("update:value", date);
    },
  },
})
export default class DatePicker extends Vue {}
